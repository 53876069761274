<template>
	<div class="container fadeEffect">
		<div id="svgContainer"></div>
	</div>
</template>

<script>
	import bodymovin from "lottie-web"
	export default {
		name: 'test',
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				pixelRatio: window.pixelRatio,
			}
		},
		mounted() {
			this.init()
		},
		destroyed() {
			
		},
		methods: {
			init() {
				var svgContainer = document.getElementById('svgContainer');
				
				var animItem = bodymovin.loadAnimation({
				  wrapper: svgContainer,
				  animType: 'svg',
				  loop: true,
				  prerender: true,
				  // autoplay: true,
				  path: './ae/markus.json', // ok
				  // path: 'https://qn.notionbase.com/notionbase/ae/HE.json',
				})
				
				animItem.setSpeed(1)
				animItem.setDirection(1)
				// animItem.playSegments([[0, 20], [50,100]], true)
				// animItem.setQuality(2)
				
				// animItem.addEventListener('loopComplete', () => {
				// 	console.log('loopComplete')
				// } )
				// animItem.addEventListener('complete', () => {
				// 	console.log('complete')
				// } )
				// animItem.addEventListener('enterFrame', () => {
				// 	console.log('enterFrame')
				// } )
				// animItem.addEventListener('segmentStart', () => {
				// 	console.log('segmentStart')
				// } )
				// animItem.addEventListener('config_ready', () => {
				// 	console.log('config_ready')
				// } )
				animItem.addEventListener('data_ready', () => {
					console.log('data_ready')
				} )
				// animItem.addEventListener('DOMLoaded', () => {
				// 	console.log('DOMLoaded')
				// } )
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		height: 100%;
		
		overflow: hidden;
	}
	
	#svgContainer {
	  max-width: 100%;
	  max-height: 100%;
	  background-color: #000;
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  margin: auto;
	}
</style>
